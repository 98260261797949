import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    message: {
        margin: theme.spacing(2, 0),
        textAlign: 'center',
    },
}));

function LoadingMessage({ children }) {
    const classes = useStyles();

    return (
        <Container maxWidth="sm">
            <div className={classes.message}>{children}</div>
        </Container>
    );
}

LoadingMessage.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};

export default LoadingMessage;
