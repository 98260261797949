import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { usePrismicPreview } from 'gatsby-source-prismic';
import { Helmet } from 'react-helmet';

import linkResolver from '../prismic/linkResolver';
import { getGlobal } from '../util/globals';
import LoadingMessage from '../components/preview/LoadingMessage';

/**
 * This is a mapping of all content types in prismic
 * that don't have UIDs to URLs, including one-time
 * page types and widgets that are static components
 * inside of larger pages.
 */
const docTypeToPath = {
    // pages
    homepage: '/construction',
    'platform-homepage': '/',
    'finance-page': '/financing',
    'gallary-page': '/portfolio',
    contact_us: 'contact-us',
    about: '/about',
    walmart_bathroom: '/walmart-bath',
    construction: '/construction',
    homeowners: '/homeowners',
    designers: '/architects',
    general_contractors: '/general-contractors',
    faq: '/faq',

    // widgets
    footer: '/',
    featured_project_types: '/',
    'get-email-modal': '/financing',
    locales: '/',
    'metadata-helmet': '/',
    'nav-bar': '/',
    platform_nav_bar: '/',
    request_a_quote_modal: '/',
    'value-callouts': '/',
};

const PreviewPage = ({ location }) => {
    const repositoryName = getGlobal('prismicRepositoryName');

    const { allSitePage } = useStaticQuery(graphql`
        {
            allSitePage {
                nodes {
                    context {
                        uid
                    }
                }
            }
        }
    `);
    const pageUIDs = allSitePage.nodes
        .map(({ context }) => (context ? context.uid : undefined))
        .filter((uid) => !!uid);

    const pathResolver = () => (doc) => {
        if (pageUIDs.includes(doc.uid)) {
            return doc.type === 'project' ? `/portfolio/${doc.uid}` : doc.uid;
        }

        return docTypeToPath[doc.type] || '/unpublished-preview';
    };

    const { previewData, path } = usePrismicPreview(location, {
        repositoryName,
        pathResolver,
        linkResolver: () => linkResolver,
    });

    useEffect(() => {
        if (previewData && path) {
            // eslint-disable-next-line no-underscore-dangle
            window.__PRISMIC_PREVIEW_DATA__ = previewData;
            navigate(path);
        }
    }, [previewData, path]);

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <LoadingMessage>Loading preview...</LoadingMessage>
        </>
    );
};

PreviewPage.propTypes = {
    location: PropTypes.object.isRequired,
};

export default PreviewPage;
